body:not(#tinymce):after {
  content: '';
  background-image: url($assets-path + "body-frame.svg");
  background-size: auto 88px;
  background-position: center;
  background-repeat: repeat-x;
  display: block;
  line-height: 0;
  height: 88px;
}

@function url-friendly-colour($colour) {
  @return '%23'+str-slice('#{$colour}', 2, -1)
}

@mixin btn-arrow(
    $bg-colour,
    $arrow-bg-colour,
    $border-colour: $bg-colour,
    $text-colour: text-contrast($bg-colour),
    $arrow-colour: text-contrast($arrow-bg-colour),
    $border-width: 1px
  ) {

  background-image: linear-gradient(to right, $bg-colour 0%, $bg-colour 50%, $arrow-bg-colour 50%);
  background-size: 200% auto;
  background-position-x: 0%;
  color: $text-colour;
  border: $border-width solid $border-colour;
  border-right: 0px !important;
  height: 25px;
  padding-right: calc(#{$btn-padding-x} + 25px) !important;
  font-size: $btn-font-size;
  display: inline-flex;
  text-decoration: none !important;
  transition: all 300ms ease;

  &:after {
    content: '';
    // This is bonkers, but the only way I can think to achieve their design.
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.87 11.16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:#{url-friendly-colour($arrow-colour)};%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_1-2'%3E%3Cline class='cls-1' y1='5.49' x2='11.62' y2='5.49'/%3E%3Cpolyline class='cls-1' points='6.94 .35 12.17 5.58 6.94 10.81'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    background-color: $arrow-bg-colour;
    color: $arrow-colour;
    border: $border-width solid $border-colour;
    border-left: 0px;
  }

  &:hover {
    background-position-x: 100%;
    color: $arrow-colour !important;
    border: $border-width solid $border-colour;

    &:after {
      background-color: $arrow-bg-colour;
      color: $arrow-colour;
    }
  }
}

.mainLogo {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mainCallToActionButtons .cta-button,
.callToActionBtn.buttonFundraise {
  @include btn-arrow($brand-primary, #fff);
}

.mainCallToActionButtons .cta-button.donate {
  @include btn-arrow(#fff, $brand-secondary, $brand-secondary, $brand-secondary, #fff);
}

.carouselSlideReadMore {
  @include btn-arrow(#fff, #000);
}

#groupSignUpBtn {
  @include btn-arrow(#000, #fff, #fff);
}

.feedsTitle, .feedList {
  max-width: $container-max-width - $site-bleed;
}

.feedItem .readMore {
  @include btn-arrow(#fff, #000, $border-width: 0px);
}

.feedItemDetailsWrapper + .readMore {
  position: absolute;
  bottom: 43px;
  left: 43px;
}

.feedItem:first-of-type .feedItemDetailsWrapper p .readMore {
  display: none;
}

.feedItem:first-of-type .feedItemDetailsWrapper + .readMore {
  left: calc(50% + 43px);
}

.homeFeedBox1 .feedList .feedItem h2, .homeFeedBox1 .feedList .feedItem .postCreateForm legend, .postCreateForm .homeFeedBox1 .feedList .feedItem legend, .homeFeedBox1 .feedList .feedItem .inMemoryCreateForm legend, .inMemoryCreateForm .homeFeedBox1 .feedList .feedItem legend, .homeFeedBox1 .feedList .feedItem .post .donationFormItems .contentBlockWrapper legend, .post .donationFormItems .contentBlockWrapper .homeFeedBox1 .feedList .feedItem legend, .homeFeedBox1 .feedList .feedItem .feedTitle {
  margin-bottom: 0
}

.carousel .buttonDonate {
  @include btn-arrow(white, $brand-secondary, $brand-secondary, $brand-secondary, #fff);
}

.menuMain {
  width: fit-content;
}

// Shrink search input when not focussed
#siteSearch {
  transition: max-width 150ms ease;
}

#siteSearch::placeholder {
  color: $body-colour;
}

#siteSearch:not(:focus):placeholder-shown {
  max-width: 75px;
  border-width: 0px;
}

#siteSearch:focus+button,
#siteSearch:not(:placeholder-shown)+button {
  border: 1px solid $header-search-input-border-colour;
  border-width: 1px 1px 1px 0;
}

.header-search .searchContainer input {
  min-height: $header-search-input-height;
}

.header-search button:before {
  content: url($assets-path + "search.svg");
  width: 15px;
  height: 15px;
}

// Nav
@media (min-width: $nav-breakpoint + 1) {

  .homeIntro {
    padding: $carousel-details-padding;
    padding: 36px $carousel-details-padding !important;
  }

  .mainLogo {
    margin-right: 100px;
  }

  // Desktop
  .pageHeaderWrapper {
    display: flex;
  }

  .menuMain {
    display: flex;
  }

  #menuMain {
    height: unset;
    align-self: stretch;

    .level1 {
      >a {
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          height: 3px;
          background-color: black;
          width: 0;
          opacity: 0;
          transition: all 300ms ease;
        }
      }

      &:hover>a {
        font-weight: 700;

        &:before {
          width: calc(100% - 40px);
          opacity: 1;
        }
      }
    }
  }

  // Nav submenu opacity
  .subMenu {
    opacity: 1;
    transition: opacity 150ms ease;
    li:hover > a {
      font-weight: 700;
    }
  }

  a[aria-expanded="true"]+.subMenu {
    opacity: 1;
  }

  .pageHeader.sticky .headerContent .header-search {
    display: flex;
  }

  .footerBox[class*="ewsletter"].newsletterWrapper>section {
    background-image: url($assets-path + "newsletter-frame.svg");
    background-repeat: no-repeat;
    padding-left: 200px;
  }

  li.hasSubmenu > a:after {
    display: none;
  }
}

// Sticky header
.pageHeader:not(.pageHeaderWrapper) {
  max-width: 100%;
}

.pageHeaderWrapper {
  padding-left: $site-bleed;
  padding-right: $site-bleed;
}

// Carousel
.carouselDetailWrapper {
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.2;
}

.carouselControls {
  position: absolute;
  margin-bottom: $spacer;
  height: fit-content;

  a {
    border: 0.5px solid white;
    font-size: $btn-font-size;

    &:hover {
      background-color: white;
      color: $brand-primary;
      border-color: white;
    }
  }

  .carouselControls a:before {
    font-family: unset;
  }

  .carouselControlNext:before,
  .carouselControlPrev:before {
    background-color: currentColor;
    content: '';
    mask-image: url($assets-path + "arrow-right.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 1em;
  }

  .carouselControlPrev:before {
    mask-image: url($assets-path + "arrow-left.svg");
  }
}

.carouselSlideTitle img {
  height: 100%;
  object-fit: cover;
}

.carouselSlideHeading {
  line-height: 1;
  margin-bottom: $site-bleed;
  a:hover {
    font-weight: 900;
  }
}

.carouselSlideSummary {
  margin-bottom: $site-bleed;
}

.carousel .cta-button,
.carousel .readMore {
  margin-top: $site-bleed;
}

// Intro
.introTextAndImg {
  max-width: $home-intro-max-width;
  margin: auto;
  padding: 0 $site-bleed;
  display: flex;
}

.intro-frame {
  background-color: $grey-lighter;
}

img:not(.mediaImage).intro-frame {
  width: 0; // Re-set with JS
  pointer-events: none;
  @media (min-width: 1304px) {
    width: 311px !important;
    height: 311px !important;
  }
}

.homeIntro {
  padding: 3rem 4rem;

  h5 {
    font-size: 1.3rem;
    color: $bright-blue;
    font-weight: 400;
  }

  .cta-button {
    @include btn-arrow($bg-colour: white,
      $arrow-bg-colour: black,
      $border-colour: black,
      $border-width: 0
    )
  }
}

// Features
.homeFeatures .homeBox1 {
  [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding-left: $carousel-details-padding;
  }
  h2 {
    margin-bottom: 2rem;
    &:before {
      content: 'Featured';
      font-size: $font-size-small;
      font-weight: 400;
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}

// Stats
.homefeaturecategory-homeboximpactstats {
  .homeImpactWrapper>h2 {
    margin-top: 33px;
    margin-bottom: 10px;
  }

  tr:nth-child(1) h2 {
    color: $red;
  }

  tr:nth-child(2) h2 {
    color: $bright-blue;
  }

  tr:nth-child(3) h2 {
    color: $green;
  }

  tr:not(:last-child) {
    border-right: 1px solid $grey;
  }
  td {
    padding: 0 1rem;
  }
}
.homefeaturecategory-homeboximpactstats td:first-child > * {
  font-weight: 800;
}

.homefeaturecategory-homeboximpactstats {
  padding-bottom: calc(#{$spacer} + 10px);
}

// Quick giving
.quick-giving-wrapper {
  display: none;
}

.homepage-quickgiving--container {
  padding-left: 0;
}

.homefeaturecategory-homeboxquickgiving {
  margin-top: 84px;
  max-width: $container-max-width - $site-bleed*2;
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel {
  .formQuestion.donationAmount:not(.donationSelected) .donationAmountFigure {
    color: black;
  }
  .formQuestion.donationAmount:not(.donationSelected):nth-child(3n+1) {
    background-color: $yellow;
  }

  .formQuestion.donationAmount:not(.donationSelected):nth-child(3n+2) {
    background-color: $green;
  }

  .formQuestion.donationAmount:not(.donationSelected):nth-child(3n+3) {
    background-color: $pink;
  }

  .formQuestion.donationAmount:not(.donationSelected).donationAmountOther {
    background-color: #dcdcdc;
  }

  .donateBtn {
    margin-top: 4px;
  }
}

.homefeaturecategory-homeboxquickgiving:after {
  content: "";
  background-image: url($assets-path + "quick-giving-border.svg");
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn {
  border-width: 1px;
  width: 100%;

  &:hover {
    color: #fff;
  }
}

.donationAmounts {
  width: fit-content;
}

.quickGivingPanel .formQuestion.donationAmount {
  margin: 8px;
}

body:not(.homepage) .quickGivingPanel .selectDonationAmount {
  padding: $site-bleed;
}

// Feeds
.feedTitle:before {
  font-size: $font-size-small;
  font-weight: 400;
  display: block;
  margin-bottom: 0.5rem;
}

.homeFeednews .feedTitle:before {
  content: 'Latest news';
}

.homeFeedappeals .feedTitle:before {
  content: 'Fundraising';
}

.feedItem:not(.tweet) {
  .readMore {
    margin-top: 1rem;
  }

  &:not(:first-child) {
    .feedItemDetailsWrapper>*:not(.feedTitle) {
      display: none;
    }
  }
}

.feedsTitle a {
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: 400;

  &:after {
    content: url($assets-path + "arrow-right.svg");
    display: inline-block;
    margin-left: 0.5rem;
    width: 13px;
  }
}

.feedList .feedItem.slick-slide {
  width: calc(100vw - (#{$card-gap-width}*2)) !important;
  margin: $card-gap-width/4;
}

// Twitter feed (not using $home-feed-twitter-enabled: true; as it doesn't work with Slick)
.homeFeedTwitter {
  .feedActionWrapper {
    display: none;
  }

  .feedsTitle {
    margin-bottom: 2rem;
  }

  .tweet {
    background-color: $grey-lighter;
    padding: 20px 27.5px;
    margin: 0 7.5px;
    time {
      font-size: 1rem;
    }
  }

  .tweetText {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  padding-bottom: 5rem;
}

.homeFeedTwitter .tweetsWrapper li a {
  text-decoration: none;
}

.twitterProfilePic {
  border-radius: 100%;
}

// Footer
// Newsletter
.footerBox[class*="ewsletter"].newsletterWrapper {
  padding: 0;
  background-repeat: no-repeat;
  background-size: contain;
  input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], input[type="search"], input[type="url"], select, textarea {
    font-size: $font-size-small;
    &::placeholder {
      font-size: $font-size-small;
    }
  }
}

.footerBox[class*="ewsletter"]:not(.newsletterWrapper) {
  display: flex;
  align-items: center;
  max-width: 1259px;

  h2 {
    width: fit-content;
  }

  p {
    margin-bottom: 0;
  }

  input[type="text"] {
    min-height: $newsletter-button-height;
    height: $newsletter-button-height;
    max-width: 135px;

    &[title="email"] {
      max-width: 210px;
    }
  }

  .featureForm>div:first-child .formQuestion {
    margin-bottom: 0;
    margin-right: 5px;
    width: fit-content;
  }

  .mceNonEditable {
    width: fit-content;
  }

  button {
    margin-bottom: 0;
  }
}

.footerBox2 {
  grid-column: 3 / span 2;
}

.footerBox3 {
  grid-column: 5 / span 6;
  line-height: 2;

  ul {
    width: 25%;
    float: left;
  }

  p {
    display: inline-block;
    margin-top: 2rem;
    font-size: 0.6rem;
    opacity: 0.5;
  }
}

.footerBox4 {
  grid-column: 13 / span 2;
}

// Inner pages
// Banner image
body[class*="PostBody"].page-has-banner,
.listing-without-feature.page-has-banner,
.page-has-banner .formDonatePage,
body.customFormPage,
body.subsite.homepage
 {
  .headerWrapper {
    display: flex;
    flex-direction: row-reverse;
    max-width: $container-max-width;
    margin: auto;
    @media (min-width: $carousel-breakpoint + 1) {
      padding-left: $site-bleed;
      padding-right: $site-bleed;  
    }
    .headerText {
      @media (min-width: $carousel-breakpoint + 1) {
        padding-left: $carousel-details-padding;
      }
    }
  }

  .carousel {
    width: 50%;
    padding: 0;
    margin: 0;
  }

  .headerText {
    width: 50%;
    padding: 2rem;
    @media (min-width: $carousel-breakpoint + 1) {
      padding-right: 150px;
    }
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .postContent {
      padding: 0;
    }
  }

  .title {
    padding: 0;
  }

  .breadcrumbWrapper {
    padding: 0;
    @media (min-width: 769px) {
      margin-top: -40px;
    }
  }
}

.formDonatePage {
  .headerText {
    background-color: $grey-lighter;
  }
  .title {
    margin-bottom: $site-bleed;
  }
}

.listing-without-feature.page-no-banner {
  .headerText {
    padding-bottom: 3rem;
    @media (min-width: $carousel-breakpoint + 1) {
      padding-left: $carousel-details-padding;
    }
  }
}

.breadcrumbItem {
  *,
  &:before {
    color: $bright-blue;
    font-weight: 700;
  }
}

.listing-without-feature {
  .headerText {
    width: 50%;
    background-color: $grey-lighter;
  }
}

// Pseudo-column layout
.postContent {
  padding-top: 60px;

  >*:not(.right) {
    padding: 0 3rem 0 $carousel-details-padding;
    width: 50% !important;
    margin-left: 0;
  }
  @media (min-width: 769px) {
    >table:not(.right) {
      width: calc(50% - 3rem) !important;
    }
  }
}

body.PostCategory_one-column-post {
  .postContent {
    >*:not(.right) {
      padding: 0 3rem 0 $carousel-details-padding;
      width: 100% !important;
      margin-left: 0;
    }
    @media (min-width: 769px) {
      >table:not(.right) {
        width: calc(100% - 3rem) !important;
      }
    }
  }
}

body.contact {
  .post .contentBlockWrapper .contentBlockWrapper.contactUsBody {
    padding: 0 3rem 0 $carousel-details-padding;
  }
  @media (min-width: 769px) {
    .headerText {
      padding-left: $carousel-details-padding;
    } 
  }
}

.officeFeedWrapper {
  display: none;
}

.listContent {
  clear: both;
}

// Blockquotes
// Splitting this out as it's long
@import "bespoke-blockquotes";

// Listing carousel
.listing-with-feature {
  .carouselSlideDetail {
    background-color: $grey-lighter;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .carouselSlideHeading {
      padding-top: 10px;
    }
  }

  .carouselSlideHeading a,
  .carouselSlideSummary {
    color: text-contrast($grey-lighter);
  }

  .carouselDetailWrapper {
    flex-direction: row;
  }

  .carousel-frame {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    transform: translateX(99%);
  }

  .carouselControls a {
    background-color: transparent;
    border-color: black;
    color: black;

    &:hover {
      background-color: black;
      color: white;
      border-color: white;
    }
  }
}

.carouselSlideTitle.showsVideo {
  position: relative;
}

// Posts without a colour category
body[class*="PostBody"]:not([class*="PostSystemCategory_post-colour"]),
body.customFormPage {
  @media (min-width: 769px) {
    .headerText {
      background-color: $light-blue;
    }
  }
  @media (max-width: 768px) {
    .pageHeader, .carousel, .headerText {
      background-color: $light-blue !important;
    }
  }
}

$post-colours: (
  "red": $red,
  "yellow": $yellow,
  "green": $green,
  "pink": $pink,
  "bright-blue": $bright-blue,
  "purple": $purple,
  "dusty-pink": $dusty-pink,
  "light-blue": $light-blue
);

@each $category,
$colour in $post-colours {

  .PostCategory_post-colour-#{$category},
  .PostSystemCategory_post-colour-#{$category} {

    .listedPostText,
    .headerText:not(.headerTextSubsite),
    .carousel {
      background-color: $colour !important;

      *:not(.readMore) {
        color: text-contrast($colour) !important;
      }
    }
    @media (max-width: $nav-breakpoint) {
      .pageHeader {
        background-color: $colour !important;
      }
    }
    .mainLogo {
      filter: unset;
    }
    @media (max-width: $cta-btn-breakpoint) {
      .menuMainAlt {
        background-color: transparent;
      }
      .menuMainAlt:before {
        background-image: url($assets-path + "menu-icon-black.svg");
      }
    }
  }
}

// Listings
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {

  margin: 13px $card-gap-width/2 ;
  min-height: 84px;

  .listed-post-image-link {
    &:before, &:after {
      width: 109px;
      height: 26px;
      position: absolute;
      left: 28px;
      bottom: 28px;
      transition: opacity 300ms ease;
    }
    &:before {
      content: url($assets-path + "read-more.svg");
    }
    &:after {
      content: url($assets-path + "read-more-hover.svg");
      opacity: 0;
    }
  }

  &.PostCategory_cta-watch-now {
    .listed-post-image-link {
      &:before, &:after {
        width: 111px;
      }
      &:before {
        content: url($assets-path + "watch-now.svg");
      }
      &:after {
        content: url($assets-path + "watch-now-hover.svg");
      }
    }
  }

  &.PostCategory_cta-register-here {
    .listed-post-image-link {
      &:before, &:after {
        width: 122px;
      }
      &:before {
        content: url($assets-path + "register-here.svg");
      }
      &:after {
        content: url($assets-path + "register-here-hover.svg");
      }
    }
  }

  &:hover {
    .listed-post-image-link {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }  
  }

}

section[onclick^="window.location='/events/"] footer, section[onclick^="window.location = '/Events/"] footer {
  display: none !important;
}

.currentProjectsHeader {
  display: none;
}

.page-no-banner {
  .headerText {
    width: 100%;
    padding-bottom: 2rem;
  }
  .carouselImageSingle {
    display: none;
  }
}

.accordianHeader .buttonIcon {
  width: 57px;
  height: 57px;  
}

.buttonIcon.expand {
  padding: 16px;
  &:before {
    content: "";
    background: url($assets-path + "arrow-faq.svg") center no-repeat;
    background-size: contain;
    width: 26px;
    height: 29px;
  }
}

.postContent {
  ul li,
  ol li {
    margin-left: 20px;
  }
}

.listContent {
  margin-top: 35px;
}

@media (min-width: 769px) {
  .carousel .headerWrapper .headerText {
    background-image: url($assets-path + "carousel-frame-inner.svg");
    background-repeat: no-repeat;
    background-position: right;
    padding-right: calc(2rem + 122px);
  }
  .listing-without-feature.page-has-banner .carousel:after {
    display: none !important;
  }
}

.searchListingPage .headerWrapper .headerText {
  background-image: unset;
}

.accordianHeader h2 + p:not(:empty) {
  margin-top: 0;
}

.appealWidgetsWrapper {
  display: none;
}

#donor-flow-iframe {
  margin-top: $site-bleed;
}

.postContent {
  h3, h4 {
    font-weight: 400;
  }
}

.postContent,
.appealActionsWrapper {
  .cta-button, .button, button, .readMore, .addToCalender {
    font-size: $font-size-base;
  }
}

.embed-container:not(.videoPopUpWrapper) {
  display: flex;
  justify-items: flex-start;
}

.post .appealActionsWrapper .appealActions .button {
  margin: 3px;
}

// Responsive
@media (min-width: $carousel-breakpoint + 1) {
  .carouselSlideDetail {
    padding: calc(#{$carousel-details-padding} + 30px) $carousel-details-padding $carousel-details-padding;
  }
  .footerBox:not([class*="ewsletter"]) {
    margin-top: 4rem;
  }
  .listedFaq {
    width: 50%;
    padding-left: calc(#{$carousel-details-padding} - #{$faq-item-header-padding};
  }
  .listingIntro {
    // padding-left: $carousel-details-padding;
    padding-right: 5px;
    > *:not(.right):not(blockquote) {
      padding-left: 0 !important;
    }
  }
  .listing-with-feature .carouselSlideDetail .carouselSlideHeading {
    padding-top: 10px;
  }
  .listing-with-feature .headerText {
    padding-left: $site-bleed;
    .breadcrumbWrapper, .title {
      padding-left: $carousel-details-padding;
    }
  }
  .post .appealActionsWrapper {
    padding: $site-bleed;
    padding-left: $carousel-details-padding;
  }
  .homepage .carouselSlideDetail {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: $nav-breakpoint + 1) {
  .menuMain .topLevel li.level1 > a {
    padding-top: 30px;
  }
  .pageHeader .headerContent {
    padding-top: 17px;
  }
}

@media (min-width: 769px) {
  .footerBox[class*="ewsletter"] > span {
    padding-right: 0;
  }
}

@media (max-width: $container-max-width) {
  .homefeaturecategory-homeboxquickgiving {
    padding-left: $site-bleed;
    padding-right: $site-bleed;
  }
  @media (min-width: 769px) {
    .homeFeeds {
      padding: 0 $site-bleed;
    }
  }
}

@media (max-width: $nav-breakpoint) {
  .menuMain {
    opacity: 1;
    transition: opacity 300ms ease;
    &:not(.active) {
      opacity: 0;
    }
  }
}

@include max-width(lg) {

  // 1024px (landscape tablets)
  .faqListingPage .contentBlock .listContent {
    margin-left: -#{$site-bleed};
    margin-right: -#{$site-bleed};
  }

  .page-has-banner .formDonatePage {
    .headerWrapper {
      display: block;
      padding: $site-bleed;
      background-color: $grey-lighter;
    }
    .carousel, .headerText {
      width: 100%;
    }
  }

  .post .appealActionsWrapper {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
    margin-top: $site-bleed;
    .appealActions {
      display: block;
    }
    .button {
      width: fit-content;
    }
  }

  .homepage .carouselSlideDetail {
    padding-bottom: 53px;
  }

  .pageHeaderWrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .listingIntro {
    padding-left: 0;
    padding-right: 5px;
    > *:not(.right) {
      padding-left: 0;
    }
  }

  .menuMain {

    .header-search,
    .mainCallToActionButtons {
      display: none !important;
    }
  }

  body.noScroll {

    .pageHeader,
    .pageHeader.sticky,
    .menuMainAlt:not(.active) {
      background-color: white;
      color: black;
    }

    .mainLogo {
      filter: unset;
    }
  }

  .menuMain li.level1>a {
    font-size: $font-size-h2;
  }

  li.hasSubmenu>a:after {
    content: "";
    background-image: url($assets-path + "chevron.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    width: 21px;
    height: 12px;
  }

  li.hasSubmenu>a.active:after {
    content: "";
    background-image: url($assets-path + "chevron-flipped.png");
  }

  .carousel {
    background-color: black;
    padding-top: 30px;
  }

  .listing-with-feature .carousel {
    background-color: $grey-lighter;
  }

  .carouselSlide img.carousel-frame {
    display: none;
  }

  body:not(.listing-without-feature) .carouselSlideTitle:not(.showsVideo),
  .listing-without-feature.page-has-banner .carousel {
    position: relative;
    background-color: transparent;
    &:after {
      content: url($assets-path + "carousel-frame-mobile.svg");
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  .carouselSlideDetail {
    padding: 0;
  }

  .carouselSlideDetail {
    padding-bottom: 1rem;
  }

  .carouselDetailWrapper {
    padding-top: 80px;
  }

  .homeIntroWrapper {
    max-width: 100%;
  }

  .introTextAndImg {
    padding: 0;
  }

  #bodyForm>section>section.homeFeaturesWrapper>div>section>div {
    padding: $site-bleed;
  }

  .homefeaturecategory-homeboxquickgiving:after {
    display: none;
  }

  .homepage {
    .quickGivingPanel .donationAmounts {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .NewsletterSign-up {
    flex-direction: column;

    >p {
      margin: 1rem 0 !important;
    }
  }

  .footerBox3 ul {
    width: 50%;
    margin-bottom: 1rem;
  }

  img:not(.mediaImage).intro-frame {
    display: none;
  }

  .homepage-quickgiving--container {
    text-align: left;
  }

  .homepage .quickGivingPanel {
    width: fit-content;
  }

  .carouselSlideDetail {
    padding-bottom: 30px;
  }

  .homepage-quickgiving--container {
    padding-right: 0;
    width: fit-content;
    margin: auto;
  }
  .quickGivingPanel .formQuestion.donationAmount .donationAmountFigure {
    padding: 13px;
  }

}

@include max-width(md) {
  // 768px (portrait tablets)

  .listing-without-feature.page-has-banner .carousel:after {
    background-size: calc(100% - #{$site-bleed}*2) auto;
  }

  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
    flex-direction: column-reverse;
  }

  .quickGivingPanel .formQuestion.donationAmount:first-of-type {
    margin-left: 8px;
  }

  .quickGivingPanel .formQuestion.donationAmount, .quickGivingPanel .formQuestion.donationAmount.donationAmountOther {
    width: calc(100% - 16px);
    margin: 8px;
  }

  body:after {
    background-image: url($assets-path + "body-frame-mobile.svg");
  }

  .footerBox[class*="ewsletter"]>h1,
  .footerBox[class*="ewsletter"]>h2,
  .postCreateForm .footerBox[class*="ewsletter"]>legend,
  .inMemoryCreateForm .footerBox[class*="ewsletter"]>legend,
  .post .donationFormItems .contentBlockWrapper .footerBox[class*="ewsletter"]>legend,
  //.footerBox[class*="ewsletter"]>span,
  .footerBox[class*="ewsletter"]:not(.newsletterWrapper) .featureForm>div:first-child .formQuestion {
    margin-left: 0;
    margin-right: 0;
  }

  .footerBox[class*="ewsletter"]>span{
    margin-left: 0;
  }

  .firstName,
  .lastName {
    width: 50% !important;

    &:first-child {
      padding-right: 5px;
    }

    &:not(:first-child) {
      padding-left: 5px;
    }
  }

  #groupSignUpBtn {
    margin-right: 0;
  }

  .NewsletterSign-up>p {
    text-align: left;
    width: 100%;
    padding: 0 $site-bleed;
  }

  .footerBox[class*="ewsletter"]:not(.newsletterWrapper) .featureForm>div:first-child .formQuestion,
  .footerBox[class*="ewsletter"]:not(.newsletterWrapper) input[type="text"],
  .footerBox[class*="ewsletter"]:not(.newsletterWrapper) input[type="text"][title="email"] {
    max-width: 100%;
    width: 100%;
    margin-bottom: 5px;
  }

  .footerBox[class*="ewsletter"] .featureForm>div:first-child {
    justify-content: end;
  }

  .pageFooter {
    display: flex;
    flex-direction: column;
  }

  body[class*="PostBody"].page-has-banner .carousel,
  .listing-without-feature .carousel,
  .listing-without-feature .headerText,
  .page-has-banner .formDonatePage .headerText,
  .page-has-banner .formDonatePage .carousel,
  body.customFormPage .headerText,
  body.customFormPage .carousel {
    width: 100% !important;
  }

  body[class*="PostBody"].page-has-banner .headerWrapper,
  .listing-without-feature .headerWrapper,
  .page-has-banner .formDonatePage .headerWrapper,
  body.customFormPage .headerWrapper {
    flex-direction: column !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .carousel {
      padding-left: $site-bleed;
      padding-right: $site-bleed;
      background-color: $grey-lighter;
    }
  }

  body[class*="PostBody"].page-has-banner .pageHeader,
  .listing-without-feature .pageHeader {
    background-color: $grey-lighter;
  }

  body[class*="PostBody"].page-has-banner .carousel,
  .listing-without-feature .carousel {
    padding: $site-bleed;
    padding-top: 0;
    background-color: $light-blue;
  }

  .postContent>*:not(.right) {
    width: 100% !important;
    padding: 0 !important;
  }

  .right {
    width: 100% !important;
    float: unset !important;
    clear: unset !important;
    &:first-of-type {
      margin-top: 0;
    }
  }

  body[class*="PostBody"].page-has-banner .headerText,
  .listing-without-feature .headerText {
    width: 100%;
  }

  .homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount,
  .homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount.donationAmountOther {
    display: inline-flex;
    width: unset;
    margin: 4px;
  }

  .quickGivingPanel .formQuestion.donationAmount .donationAmountFigure {
    font-size: $font-size-base;
  }

  .homefeaturecategory-homeboxquickgiving h2 {
    font-size: 2rem;
  }

  .homefeaturecategory-homeboximpactstats tr:not(:last-child) {
    border-right: 0px;
  }

  .homeIntro {
    padding: 2rem 1rem;
  }

  .mainLogo,
  .pageHeader.sticky .mainLogo {
    width: 35px;
    height: 33px;
  }

  .homeFeatures .homeBox1 {
    margin: 0;
  }

  .homeFeatures {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .homeFeed .feedsTitle {
    font-size: $font-size-h2;

    a {
      width: 100%;
      display: block;
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }

  .feedList .feedItem .feedTitle {
    font-size: $font-size-h4 !important;
  }

  .feedList .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
  .homeFeedBox1 .feedList .feedItem [class^="listed"][class$="Text"] {
    padding: 1rem !important;
  }

  .feedItem:not(.tweet) .feedItemDetailsWrapper p {
    display: none !important;
  }

  .footerBox1:after {
    content: url($assets-path + "body-frame-mobile.svg");
    display: block;
    line-height: 0;
    margin-top: 10px;
  }

  .footerBox2 {
    order: 0;
  }

  .footerBox3 {
    order: 2;
  }

  .footerBox4 {
    order: 1;
  }

  .homeFeed .feedsTitle {
    padding-left: $site-bleed;
    padding-right: $site-bleed;
  }

  .feedList .feedItem.slick-slide {
    width: calc(100vw - (30px*2)) !important;
    margin: 7.5px;
  }

  .homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
    font-size: 2rem;
  }

  body:not(.donatePage) .listingIntro.postContent {
    margin-top: $gap-width;
  }

  .homeIntro {
    padding: 30px;
  }

  .homefeaturecategory-homeboximpactstats td {
    padding: 0;
  }
  .homefeaturecategory-homeboximpactstats tr {
    margin: $site-bleed/2 0;
  }

  .NewsletterSign-up h2 {
    width: 100% !important;
    text-align: left !important;
  }

  .footerBox:not([class*="ewsletter"]) {
    margin: 2rem $site-bleed 0;
  }
}

@include max-width(sm) {

  // 576px (mobile)
  blockquote {
    font-size: 1.1rem;
    padding: 3rem !important;
  }
  .accordianHeader {
    padding: 2rem;
  }

  .menuMainAlt:before {
    content: '';
    width: 32px;
    height: 21px;
    margin-right: 0;
    background-image: url($assets-path + "menu-icon.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }

  .mainLogo {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  body[class*="PostBody"].page-has-banner .pageHeader,
  .listing-without-feature .pageHeader {
    .mainLogo {
      filter: unset;
    }
    .menuMainAlt {
      background-color: transparent;
    }
    .menuMainAlt:not(.active):before {
      background-image: url($assets-path + "menu-icon-black.svg");
    }
  }

  .headerContent .cta-button:not(.persist) {
    display: none !important;
  }

  .pageHeader,
  .pageHeader.sticky {
    background-color: black;
  }

  .listing-with-feature,
  .donatePage {
    .pageHeader,
    .pageHeader.sticky {
      background-color: $grey-lighter;
      .mainLogo {
        filter: unset;
      }
      .menuMainAlt {
        background-color: transparent;
      }
      .menuMainAlt:not(.active):before {
        background-image: url($assets-path + "menu-icon-black.svg");
      }
    }
    .carousel {
      padding-top: 0;
    }
  }

  .mainLogo {
    filter: invert(100%);
  }

  .pageHeader.sticky .mainLogo {
    background-image: url($assets-path + "logo-icon.svg");
  }

  .menuMainAlt {
    font-size: 0;

    &:before {
      font-size: 2rem;
    }
  }

  .listing-without-feature {

    .pageHeader,
    .pageHeader.sticky,
    .menuMainAlt {
      background-color: $grey-lighter;
    }

    .mainLogo {
      filter: unset;
    }

    .menuMainAlt:not(.active):before {
      background-image: url($assets-path + "menu-icon-black.svg");
    }
  }

  .menuMainAlt.active:before {
    width: 27px;
    height: 27.5px;
  }

  .homefeaturecategory-homeboximpactstats td:first-child > * {
    font-size: 5rem;
  }

  .menuMainAlt.active {
    background-color: transparent;
  }

  .menuMainAlt.active:before {
    content: "";
    background-image: url($assets-path + "menu-close-icon.png");
    background-color: transparent;
    width: 27px;
    height: 27px;
  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .homepage .carousel {
    padding-top: 0;
  }
  @include min-width(md) {
    .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) [class*="DetailsWrapper"]:not(.publishDetailsWrapper), .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) [class^="listed"][class$="Text"] {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@supports(display: grid) {
  @include min-width(sm) {
    .customForm .checkboxRadioGrid .answerOptions {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.customForm .buttonContainer {
  text-align: left;
  padding-left: $carousel-details-padding + $site-bleed;
  @media (max-width: 768px) {
    padding-left: $site-bleed;
  }
}

body[class*=PostBody].page-has-banner .title, .listing-without-feature.page-has-banner .title, .page-has-banner .formDonatePage .title, body.customFormPage .title{
  margin-bottom: 1em;
}



.formThanksText {
  display: block;
  margin: 0 20%;
}

// Bug 421107 - Custom form - Title to left aligned and logo to be in black
body.customFormPage .title {
  left:0px !important;
}

body.customFormPage {
  .mainLogo {
    filter: unset;
  }
}

// 810138 - Parent nav item clickable - mobile
@media (max-width: 1024px) {
  .menuMain ul li>span {
		display: flex;
		&:after {      
      content: "";
      background-image: url("../assets/chevron.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      width: 21px;
      height: 12px;    
      margin-right: 10px;
		}
		&.active {
			&:after {
        content: "";
        background-image: url("../assets/chevron-flipped.png");
      }
		}
	}
}


// Subsites

body.subsite {

&:not(.homepage) .headerWrapperSubsite, .subsite:not(.homepage) .subsiteBody {
    display: none !important;
}


&:not(.homepage) section.headerText.headerTextSubsite {
    max-width: 1244px;
    width: 100%;
    margin: auto;
    padding-bottom: 2rem;
}

@media (min-width: 1025px) {
  &:not(.homepage) .headerText:not(.headerTextSubsite) {
    max-width: 1244px!important;
  }
}

.menuSub ul.topLevel > li > a:hover {
  font-weight: bold;
}

.headerText {
  background-color: $grey-lighter;
  padding-right: 55px;
}

.contentContainer .menuSub, .subsite .post .menuSub {
  order: 3;
}

// nav#menuSub {
//     background: rgb(116, 76, 153) !important;
//     padding: 0;
//     margin: 0;
//     max-width: 100%;
//     padding: 10px;
// }

@media (max-width: 768px) {

  &[class*="PostBody"].page-has-banner .headerWrapper .carousel {
    padding-top: 40px;
  }

}

}