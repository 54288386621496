blockquote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.green {
    background-color: $green;
    color: #fff;
    padding: 6rem;
    * {
      color: #fff;
      z-index: 1;
    }
    &:before {
      content: "";
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-color: black;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) scale(0.85);
      transition: all 300ms ease;
    }
    &:hover {
      &:before {
        transform: translateY(-50%) scale(0.75);
      }
    }
  }
  &.purple {
    background-color: $purple;
    color: #fff;
    padding: 6rem;
    * {
      color: #fff;
      z-index: 1;
    }
    &:before {
      content: url($assets-path + "bq-purple.svg");
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.75);
      transition: all 300ms ease;
    }
    &:hover {
      &:before {
        transform: scale(0.8);
      }
    }
  }
  &.blue {
    background-color: $grey-lighter;
    color: text-contrast($grey-lighter);
    * {
      z-index: 1;
    }
    > *:first-child:before {
      content: url($assets-path + "bq-blue.svg");
      max-width: 58px;
      display: block;
      margin: auto;
      margin-bottom: 1rem;
      transition: all 300ms ease;
    }
    &:hover {
      > *:first-child:before {
        transform: rotate(180deg);
      }
    }
  }
  &:not([class]),
  &.light-blue {
    background-color: $grey-lighter;
    color: text-contrast($grey-lighter);
    * {
      z-index: 1;
    }
    &:before {
      content: url($assets-path + "bq-light-blue.svg");
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(0deg) scale(0.85);
      transition: all 300ms ease;
    }
    &:hover {
      &:before {
        transform: rotate(90deg) scale(0.9);
      }
    }
  }
  &.lavender {
    background-color: $grey-lighter;
    color: text-contrast($grey-lighter);
    * {
      z-index: 1;
    }
    &:before {
      content: url($assets-path + "bq-lavender.svg");
      width: 133px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      transition: all 300ms ease;
    }
    &:hover {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  &.gold {
    background-color: $grey-lighter;
    color: text-contrast($grey-lighter);
    overflow: hidden;
    * {
      z-index: 1;
    }
    &:before {
      content: url($assets-path + "bq-gold.svg");
      width: 230px;
      position: absolute;
      top: -30px;
      left: -30px;
      transition: all 300ms ease;
    }
    &:hover {
      &:before {
        transform: rotate(-180deg);
      }
    }
  }
}