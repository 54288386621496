//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab ($number-of-cards: 3,
    $prefab: 1);

  .homeBox1 {
    @include card($card-details-background-colour: $yellow,
      $card-hover-details-background-colour: $yellow,
      $card-details-padding: 5rem,
      $card-summary-font-size: 1rem,
      $card-border: 0px
    );
    @include card-side-by-side($card-side-by-side-image-position: right,
      $card-side-by-side-vertical-text-position: center);
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab ($number-of-feeds: 2,
  $prefab: 1);

// Specify a card layout for each individual feed
.homeFeed .feedList {
  @include cards-layout-prefab ($number-of-cards: 5,
    $prefab: 2);

  // Choose your card style for home feed cards
  .feedItem {
    @include card($card-details-background-colour: $purple,
      $card-hover-details-background-colour: $purple,
      $card-summary-colour: white,
      $card-hover-summary-colour: white,
      $card-heading-colour: white,
      $card-hover-heading-colour: white,
      $card-details-padding: 2rem);
    @include card-basic($card-basic-image-position: below);
  }

  .feedItem:nth-child(1) {
    @include card($card-details-background-colour: $purple,
      $card-hover-details-background-colour: $purple,
      $card-details-padding: 87px 43px 43px,
      $card-summary-font-size: 1rem,
      $card-heading-colour: white,
      $card-summary-colour: white,
      $card-hover-heading-colour: white,
      $card-hover-summary-colour: white);
    @include card-side-by-side($card-side-by-side-vertical-text-position: top);
    // Should be 2/3 width
    flex-basis: calc((100% - (#{$gap-width}*2)) / 3 * 2);
  }

  // 2nd feeditem
  .feedItem:nth-child(2) {
    // Should be 1/3 width
    flex-basis: calc((100% - (#{$gap-width}*4)) / 3);
  }
}

.homeFeed:nth-child(even) .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 5,
    $prefab: 2
  );

  // Choose your card style for home feed cards
  .feedItem {
    @include card($card-details-background-colour: $yellow,
      $card-hover-details-background-colour: $yellow,
      $card-summary-colour: black,
      $card-hover-summary-colour: black,
      $card-heading-colour: black,
      $card-hover-heading-colour: black,
      $card-details-padding: 2rem);
    @include card-basic($card-basic-image-position: below);
  }

  .feedItem:nth-child(1) {
    @include card($card-details-background-colour: $yellow,
      $card-hover-details-background-colour: $yellow,
      $card-details-padding: 87px 43px 43px,
      $card-summary-font-size: 1rem,
      $card-heading-colour: black,
      $card-summary-colour: black,
      $card-hover-heading-colour: black,
      $card-hover-summary-colour: black);
    @include card-side-by-side($card-side-by-side-vertical-text-position: top);
    // Should be 2/3 width
    flex-basis: calc((100% - (#{$gap-width}*2)) / 3 * 2);
  }

  // 2nd feeditem
  .feedItem:nth-child(2) {
    // Should be 1/3 width
    flex-basis: calc((100% - (#{$gap-width}*4)) / 3);
  }
}

// .homeFeedBox3 .feedList {
//   @include cards-layout-prefab (
//     $number-of-cards: 3,
//     $prefab: 1
//   );
// }

//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-text-overlay (
    $card-text-overlay-details-position-y: top,
    $card-text-overlay-show-summary-on-hover: true
  );
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}